import { makeStyles } from "tss-react/mui";
import { Key, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import EventIcon from "@mui/icons-material/Event";
import { IconButton, TableCell as MuiTableCell, TableHead as MuiTableHead, TableRow as MuiTableRow, Theme } from "@mui/material";
import { styled } from "@mui/system";
import moment from "moment";
import { TableItem, TableProps } from ".";
import { TranslationFunction } from "translations/Translation";
import { useTranslation } from "utils-ts/hooks";
import { NestedKeyOf } from "Shared";
import { Autocomplete, Checkbox, DatePicker, DateTimePicker, Select, TextField } from "components-ts/controls";
import { ActionColumn, ValueColumn } from "./TableTypes";
import useTableStyles from "./tableCommonStyles";

const useHeaderStyles = makeStyles()((theme: Theme) => ({
    headerCell: {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        wordBreak: "break-word",
    },
    borderTopLeft: {
        borderRadius: "7px 0px 0px 0px",
    },
    borderTopRight: {
        borderRadius: "0px 7px 0px 0px",
    },
    label: {
        color: theme.palette.primary.contrastText,
    },
    inputRoot: {
        "color": theme.palette.primary.contrastText,
        "input": {
            color: theme.palette.primary.contrastText,
            borderColor: theme.palette.primary.contrastText,
        },
        "&.Mui-focused": {
            color: theme.palette.primary.contrastText,
            borderBottomColor: theme.palette.primary.contrastText,
        },
        "& label": {
            color: theme.palette.primary.contrastText,
        },
        "& label.Mui-focused": {
            color: theme.palette.primary.contrastText,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: theme.palette.primary.contrastText,
        },
        "& .MuiInput-underline:hover:not($disabled):not($error)": {
            borderBottomColor: theme.palette.primary.contrastText,
        },
        "& .MuiInput-underline:before": {
            borderBottomColor: theme.palette.primary.contrastText,
        },
        "& .MuiAutocomplete-clearIndicator": {
            color: theme.palette.primary.contrastText,
        },
        "& .MuiAutocomplete-popupIndicator": {
            color: theme.palette.primary.contrastText,
        },
        ":before": {
            borderColor: theme.palette.primary.contrastText,
        },
        ":after": {
            borderColor: theme.palette.primary.contrastText,
        },
    },
}));

const OpenPickerWhiteIcon = styled(EventIcon)({
    color: "#fff",
});

const ClearWhiteIcon = styled(ClearIcon)({
    color: "#fff",
});

const isAction = <T extends TableItem>(column: ActionColumn<T> | ValueColumn<T>): column is ActionColumn<T> => {
    return (column as ActionColumn<T>).actionType !== undefined;
};

const FiltrableHead = <T extends TableItem>({
    column,
    filter,
    setFilter,
    t,
    onFilterChange,
    classes,
    size,
}: {
    column: ValueColumn<T>;
    filter: T;
    setFilter: (filter: T) => void;
    t: TranslationFunction;
    onFilterChange?: (name: NestedKeyOf<T>, value?: unknown) => void;
    classes: Record<"headerCell" | "borderTopLeft" | "borderTopRight" | "inputRoot" | "label", string>;
    size?: "small" | "medium";
}) => {
    const clearFilterValue = () => {
        setFilter({
            ...filter,
            [column.property.toString()]: undefined,
        });

        if (onFilterChange && !isAction(column)) {
            onFilterChange(column.property, undefined);
        }
    };
    const clearIconAdornment = (
        <IconButton onClick={clearFilterValue}>
            <ClearIcon className={classes.headerCell} />
        </IconButton>
    );

    const propertyName = column.property.toString();
    const label = column.label ? t(column.label).toString() : "";
    const value = filter[propertyName];
    const filterAs = column?.filterAs ?? column?.as;

    switch (filterAs) {
        case "select":
            return (
                <Select
                    label={label}
                    value={value?.toString()}
                    classes={{
                        root: classes.inputRoot,
                        icon: classes.label,
                    }}
                    items={column.filterItems || []}
                    onChange={(value) => {
                        setFilter({
                            ...filter,
                            [propertyName]: value,
                        });

                        if (onFilterChange && !isAction(column)) {
                            onFilterChange(column.property, value);
                        }
                    }}
                    size={size}
                />
            );

        case "autocomplete":
            return (
                <Autocomplete
                    label={label}
                    value={value?.toString()}
                    classes={{
                        root: classes.inputRoot,
                    }}
                    items={column.filterItems || []}
                    onChange={(value) => {
                        setFilter({
                            ...filter,
                            [propertyName]: value,
                        });

                        if (onFilterChange && !isAction(column)) {
                            onFilterChange(column.property, value);
                        }
                    }}
                />
            );

        case "date":
            return (
                <DatePicker
                    label={label}
                    value={value?.toString()}
                    classes={{
                        root: classes.inputRoot,
                    }}
                    onChange={(value) => {
                        setFilter({
                            ...filter,
                            [propertyName]: value,
                        });

                        if (onFilterChange && !isAction(column)) {
                            onFilterChange(column.property, value);
                        }
                    }}
                    slots={{
                        openPickerIcon: OpenPickerWhiteIcon,
                        clearIcon: ClearWhiteIcon,
                    }}
                />
            );

        case "dateTime":
            return (
                <DateTimePicker
                    label={label}
                    value={moment.isMoment(value) ? value : value?.toString()}
                    classes={{
                        root: classes.inputRoot,
                    }}
                    onChange={(value) => {
                        setFilter({
                            ...filter,
                            [propertyName]: value,
                        });

                        if (onFilterChange && !isAction(column)) {
                            onFilterChange(column.property, value);
                        }
                    }}
                    slots={{
                        openPickerIcon: OpenPickerWhiteIcon,
                        clearIcon: ClearWhiteIcon,
                    }}
                />
            );

        case "boolean":
            return (
                <Checkbox
                    label={label}
                    value={value === undefined ? false : Boolean(value)}
                    indeterminate={value === undefined ? undefined : value === false}
                    classes={{
                        root: classes.inputRoot,
                    }}
                    labelPlacement="top"
                    labelClasses={{
                        root: classes.label,
                    }}
                    onChange={(value) => {
                        let newValue = value;
                        if (column.transformFilterValue) {
                            newValue = column.transformFilterValue(value) as boolean | undefined;
                        }

                        setFilter({
                            ...filter,
                            [propertyName]: newValue,
                        });

                        if (onFilterChange && !isAction(column)) {
                            onFilterChange(column.property, newValue);
                        }
                    }}
                    size={size}
                    sx={{
                        "color": "#fff",
                        "&.Mui-checked": {
                            color: "#fff",
                        },
                        "&.MuiCheckbox-indeterminate": {
                            color: "#fff",
                        },
                    }}
                />
            );

        case "decimal":
        case "integer":
            return (
                <TextField
                    numberType={column.as === "decimal" ? "decimal" : "numeric"}
                    label={label}
                    onChange={(newValue) => {
                        const newNumber =
                            column.as === "decimal" ? Number.parseFloat(newValue?.replace(",", ".")) : Number.parseInt(newValue?.replace(",", "."));
                        if (newNumber === 0 && newValue !== "0") {
                            clearFilterValue();
                            return;
                        }

                        if (isNaN(newNumber)) {
                            return;
                        } else if (column.as === "decimal" && !Number.isFinite(newNumber)) {
                            return;
                        } else if (column.as === "integer" && !Number.isInteger(newNumber)) {
                            return;
                        }

                        setFilter({
                            ...filter,
                            [propertyName]: newNumber,
                        });

                        if (onFilterChange && !isAction(column)) {
                            onFilterChange(column.property, newNumber);
                        }
                    }}
                    value={value?.toString()}
                    classes={{
                        root: classes.inputRoot,
                    }}
                    adornment={{ position: "end", value: clearIconAdornment }}
                />
            );

        case "string":
        case undefined:
        default:
            return (
                <TextField
                    label={label}
                    onChange={(newValue) => {
                        setFilter({
                            ...filter,
                            [propertyName]: newValue,
                        });

                        if (onFilterChange && !isAction(column)) {
                            onFilterChange(column.property, newValue);
                        }
                    }}
                    value={value?.toString()}
                    classes={{
                        root: classes.inputRoot,
                    }}
                    adornment={{ position: "end", value: clearIconAdornment }}
                />
            );
    }
};

const TableHead = <T extends TableItem>({
    columns,
    handlers,
    initialFilters,
}: Pick<TableProps<T>, "columns" | "handlers" | "initialFilters">): JSX.Element => {
    const { t } = useTranslation();
    const [filter, setFilter] = useState<T>((initialFilters || {}) as T);
    const { classes } = useTableStyles();
    const { classes: headerClasses, cx } = useHeaderStyles();
    const actionCells = columns.filter(isAction) || [];
    return (
        <MuiTableHead>
            <MuiTableRow>
                {columns
                    .filter((c) => c.hideColumn !== true)
                    .map((c, i) => {
                        const { label } = c;
                        const valueColumn = c as ValueColumn<T>;
                        const actionColumn = isAction(c) ? c : undefined;
                        const isLastActionCell = actionColumn !== undefined && actionCells.at(-1)?.actionType === actionColumn.actionType;
                        const cellTypeStyle =
                            valueColumn.as === "date"
                                ? classes.dateCell
                                : valueColumn.as === "boolean"
                                ? classes.booleanCell
                                : valueColumn.as === "decimal" || valueColumn.as === "integer" || valueColumn.as === "percent"
                                ? classes.numericCell
                                : undefined;

                        return (
                            <MuiTableCell
                                className={cx(
                                    headerClasses.headerCell,
                                    i === 0 ? headerClasses.borderTopLeft : i === columns.length - 1 ? headerClasses.borderTopRight : undefined,
                                    cellTypeStyle
                                )}
                                key={`${!isAction(c) ? c.property : c.actionType}-${i}` as Key}
                                {...c.cellProps}
                            >
                                {valueColumn !== undefined && valueColumn.filtrable ? (
                                    <FiltrableHead
                                        column={valueColumn}
                                        filter={filter}
                                        setFilter={setFilter}
                                        onFilterChange={handlers?.onFilterChange}
                                        classes={headerClasses}
                                        t={t}
                                        size={c.cellProps?.size}
                                    />
                                ) : actionColumn !== undefined && actionColumn.actionType === "select" ? (
                                    <Checkbox
                                        label=""
                                        value={actionColumn.isAllSelected()}
                                        onChange={(newValue) => {
                                            if (handlers?.onSelectAllChange) {
                                                handlers.onSelectAllChange(newValue);
                                            }
                                        }}
                                        classes={{
                                            root: headerClasses.inputRoot,
                                        }}
                                        indeterminate={!actionColumn.isAllSelected() && actionColumn.isAnySelected()}
                                        sx={{
                                            "color": "#fff",
                                            "&.Mui-checked": {
                                                color: "#fff",
                                            },
                                            "&.MuiCheckbox-indeterminate": {
                                                color: "#fff",
                                            },
                                        }}
                                    />
                                ) : label ? (
                                    t(label).toString()
                                ) : isLastActionCell && handlers?.onFilterClear && columns.some((c) => "filtrable" in c && c.filtrable) ? (
                                    <IconButton
                                        onClick={() => {
                                            setFilter({} as T);
                                            if (handlers?.onFilterClear !== undefined) {
                                                handlers.onFilterClear();
                                            }
                                        }}
                                    >
                                        <ClearIcon className={headerClasses.headerCell} />
                                    </IconButton>
                                ) : undefined}
                            </MuiTableCell>
                        );
                    })}
            </MuiTableRow>
        </MuiTableHead>
    );
};

export default TableHead;
