import React from "react";
import { Paths } from "routing-ts/ManagerPaths";
import { PrivateRoute } from "routing-ts/PrivateRoute";
import { TradeMarketingDepartment } from "../roles";

const PromotionPeriodsList = React.lazy(() =>
    import("views/bridge").then((module) => ({
        default: module.PromotionPeriodsList,
    }))
);
const PromotionPeriod = React.lazy(() =>
    import("views/bridge").then((module) => ({
        default: module.PromotionPeriod,
    }))
);
const MarketingToolsList = React.lazy(() =>
    import("views/bridge").then((module) => ({
        default: module.MarketingToolsList,
    }))
);
const MarketingTool = React.lazy(() =>
    import("views/bridge").then((module) => ({
        default: module.MarketingTool,
    }))
);
const ToolkitsList = React.lazy(() =>
    import("views/bridge").then((module) => ({
        default: module.ToolkitsList,
    }))
);
const Toolkit = React.lazy(() =>
    import("views/bridge").then((module) => ({
        default: module.Toolkit,
    }))
);
const ReservationKitsList = React.lazy(() =>
    import("views/bridge").then((module) => ({
        default: module.ReservationKitsList,
    }))
);
const ReservationKit = React.lazy(() =>
    import("views/bridge").then((module) => ({
        default: module.ReservationKit,
    }))
);
const ProductPromotionPropositionForm = React.lazy(() =>
    import("views-ts/bridge").then((module) => ({
        default: module.ProductPromotionPropositionForm,
    }))
);
const ProductPromotionPropositionsList = React.lazy(() =>
    import("views-ts/bridge").then((module) => ({
        default: module.ProductPromotionPropositionsList,
    }))
);
const PromotionCreatorSearch = React.lazy(() =>
    import("views-ts/bridge").then((module) => ({
        default: module.PromotionCreatorSearch,
    }))
);
const PromotionCreatorForm = React.lazy(() =>
    import("views-ts/bridge/promotionCreator").then((module) => ({
        default: module.PromotionCreatorForm,
    }))
);

const PromotionCreatorView = React.lazy(() =>
    import("views-ts/bridge/promotionCreator").then((module) => ({
        default: module.PromotionCreatorView,
    }))
);

export const Bridge: PrivateRoute[] = [
    new PrivateRoute(
        "/bridge",
        ["Catman", "SalesAssistant", "SalesManager", ...TradeMarketingDepartment.GetRoles()],
        undefined,
        "bridge",
        undefined,
        [
            new PrivateRoute(
                Paths.Bridge.PromotionPeriodsList,
                TradeMarketingDepartment.GetRoles(),
                PromotionPeriodsList,
                "promotionPeriods",
                "date_range"
            ),
            new PrivateRoute(Paths.Bridge.PromotionPeriodForm, TradeMarketingDepartment.GetRoles(), PromotionPeriod),

            new PrivateRoute(Paths.Bridge.MarketingToolsList, TradeMarketingDepartment.GetRoles(), MarketingToolsList, "marketingTools", "widgets"),
            new PrivateRoute(Paths.Bridge.MarketingToolForm, TradeMarketingDepartment.GetRoles(), MarketingTool),

            new PrivateRoute(Paths.Bridge.ToolkitsList, TradeMarketingDepartment.GetRoles(), ToolkitsList, "toolkits", "widgets"),
            new PrivateRoute(Paths.Bridge.ToolkitForm, TradeMarketingDepartment.GetRoles(), Toolkit),

            new PrivateRoute(
                Paths.Bridge.ReservationKitsList,
                ["Catman", "SalesAssistant", "SalesManager", ...TradeMarketingDepartment.GetRoles()],
                ReservationKitsList,
                "reservationKits",
                "event_node"
            ),
            new PrivateRoute(
                Paths.Bridge.ReservationKitForm,
                ["Catman", "SalesAssistant", "SalesManager", ...TradeMarketingDepartment.GetRoles()],
                ReservationKit
            ),
            new PrivateRoute(
                Paths.Bridge.ProductPromotionPropositionsList,
                ["Catman", "SalesAssistant", "SalesManager", ...TradeMarketingDepartment.GetRoles()],
                ProductPromotionPropositionsList,
                "promotionCreatorDraftsList",
                "calculate"
            ),
            new PrivateRoute(
                Paths.Bridge.ProductPromotionPropositionForm,
                ["Catman", "SalesAssistant", "SalesManager", ...TradeMarketingDepartment.GetRoles()],
                ProductPromotionPropositionForm,
            ),
            new PrivateRoute(
                Paths.Bridge.PromotionCreatorSearch,
                ["Catman", "SalesAssistant", "SalesManager", ...TradeMarketingDepartment.GetRoles()],
                PromotionCreatorSearch,
                "promotionCreatorSearch",
                "pageview"
            ),
            new PrivateRoute(
                Paths.Bridge.PromotionCreatorForm,
                ["Catman", "SalesAssistant", "SalesManager", ...TradeMarketingDepartment.GetRoles()],
                PromotionCreatorForm,
            ),
            new PrivateRoute(
                Paths.Bridge.PromotionCreatorView,
                ["Catman", "SalesAssistant", "SalesManager", ...TradeMarketingDepartment.GetRoles()],
                PromotionCreatorView,
            ),
        ]
    ),
];
