/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    ControllerFieldState,
    ControllerProps,
    ControllerRenderProps,
    FieldPath,
    FieldValues,
    Controller as HookController,
    UseFormStateReturn,
} from "react-hook-form";
import { useFormRequiredFieldContext, usePrefixContext } from "components-ts/forms/contexts";
import { default as ControlRoot, ControlRootProps } from "./ControlRoot";

type Props<T, TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> = Omit<
    ControllerProps<TFieldValues, TName>,
    "defaultValue" | "disabled" | "render"
> &
    Pick<ControlRootProps, "width" | "useGridItem"> & {
        render: ({
            field,
            fieldState,
            formState,
        }: {
            field: ControllerRenderProps<TFieldValues, TName> & { required?: boolean };
            fieldState: ControllerFieldState;
            formState: UseFormStateReturn<TFieldValues>;
        }) => React.ReactElement;
        transform?: (value?: T) => T;
        defaultValue: T | any;
        emptyValue?: T | any;
        fullWidth?: boolean;
        manualUnregister?: boolean;
};

const Controller = <T, TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
    name,
    render,
    defaultValue,
    transform,
    useGridItem,
    emptyValue,
    width,
    manualUnregister,
}: Props<T, TFieldValues, TName>): JSX.Element => {
    const requiredFields = useFormRequiredFieldContext();
    const prefix = usePrefixContext();
    const key = prefix ? `${prefix}.${name}` : `${name}`;

    return (
        <HookController<TFieldValues, TName>
            key={key}
            name={prefix ? (`${prefix}.${name}` as TName) : name}
            defaultValue={defaultValue}
            shouldUnregister={manualUnregister === true ? false : !/\.?\d\./.test(key)}
            render={({ field: { onChange, ...fieldRest }, fieldState, formState }) => {
                return (
                    <ControlRoot
                        useGridItem={useGridItem}
                        width={width}
                    >
                        {render({
                            field: {
                                ...fieldRest,
                                onChange: (value) => {
                                    const transformedValue = transform ? transform(value) : value;
                                    if (value || value === 0 || value === false) {
                                        onChange(transformedValue);
                                    } else {
                                        onChange(emptyValue === undefined ? "" : emptyValue);
                                    }
                                },
                                required: (requiredFields || []).includes(key),
                            },
                            fieldState,
                            formState,
                        })}
                    </ControlRoot>
                );
            }}
        />
    );
};

export default Controller;
