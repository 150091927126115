import { generatePath } from "react-router-dom";
import { PromotionCreatorFormModel } from "Operations";
import { useCreateOrUpdateMutation, useGetQuery } from "api/hooks";

const url = (id?: string) =>
    generatePath(`api/bridge/promotion-creator/:id?`, {
        id: id ? id : null,
    });

export const useCreateOrUpdatePromotionCreator = (id?: string) => {
    return useCreateOrUpdateMutation<PromotionCreatorFormModel, PromotionCreatorFormModel>({
        app: "operations",
        url: url(id),
        id: id,
    });
};

export const useGetPromotionCreator = (id?: string) => {
    return useGetQuery<PromotionCreatorFormModel>({
        app: "operations",
        url: url(id),
        shouldMakeRequest: Boolean(id),
        refetchOnMount: true,
    });
};
