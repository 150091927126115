import { generatePath } from "react-router-dom";
import {
    ProductPromotionProposition,
    ProductPromotionPropositionListResponse,
    ProductPromotionPropositionPageRequest,
    ProductPromotionPropositionSearchParameters,
    ProductPromotionPropositionSearchResult,
} from "Operations";
import { useCreateOrUpdateMutation, useGetPagedQuery, useGetQuery } from "api/hooks";

const url = (productPromotionPropositionId?: string) =>
    generatePath(`api/bridge/product-promotion-proposition/:productPromotionPropositionId?`, {
        productPromotionPropositionId: productPromotionPropositionId ? productPromotionPropositionId : null,
    });

export const useFindProductPromotionPropositions = (initialParams: ProductPromotionPropositionPageRequest) => {
    return useGetQuery<ProductPromotionPropositionListResponse, ProductPromotionPropositionPageRequest>({
        app: "operations",
        url: url(),
        queryParams: initialParams,
    });
};

export const useGetProductPromotionProposition = (productPromotionPropositionId?: string) => {
    return useGetQuery<ProductPromotionProposition>({
        app: "operations",
        url: url(productPromotionPropositionId),
        shouldMakeRequest: Boolean(productPromotionPropositionId),
        refetchOnMount: true,
    });
};

export const useCreateOrUpdateProductPromotionProposition = (id?: string) => {
    return useCreateOrUpdateMutation<ProductPromotionProposition, ProductPromotionProposition>({
        app: "operations",
        url: url(id),
        id: id,
    });
};

export const useChangeProductPromotionPropositionStatus = (id?: string) => {
    return useCreateOrUpdateMutation<ProductPromotionProposition, ProductPromotionProposition>({
        app: "operations",
        url: url(id) + "/finish",
        id: id,
    });
};

export const useSearchPromotionPropositions = (
    params: ProductPromotionPropositionSearchParameters = {
        pageIndex: 1,
        pageSize: 25,
        includeFacets: true,
    }
) => {
    return useGetPagedQuery<ProductPromotionPropositionSearchResult, ProductPromotionPropositionSearchParameters>({
        app: "operations",
        url: url() + "/search",
        queryParams: params,
    });
};
